<template>
  <div class="my-5">
    <v-card class="overflow-hidden">
      <v-row no-gutters class="align-center justify-left">
        <v-col sm="5" class="position-relative d-flex align-center black">
          <iframe class="mx-auto" width="360" height="360"
            :src="videoLoaded ? videoUrl : require('@/assets/PageMainHomeV6/TrailerThumbnail.png')" title="YouTube Shorts"
            frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
          </iframe>
        </v-col>
        <v-col sm="7" class="text-center d-flex justify-center flex-column py-5">
          <div class="text-h2 mb-4">{{ title[locale] }}</div>
          <div class="mb-4">{{ description[locale] }}</div>
          <div>
            <v-btn x-large color="yellow darken-5" rounded class="shiny-golden-btn animate-glow"
              :to="{ name: 'PageMainRegister' }">
              <span class="brown--text">{{ cta[locale] }}</span>
            </v-btn>
          </div>
        </v-col>
        <!-- <v-col sm="6" class="text-center d-flex flex-column align-center px-6">
          <div class="text-h4 font-weight-bold mb-4">{{ title[locale] }}</div>
          <div class="text-body-1 mb-4">{{ description[locale] }}</div>
          <v-btn
            x-large
            color="yellow darken-5"
            rounded
            class="shiny-golden-btn animate-glow"
            :to="{ name: 'PageMainRegister' }"
          >
            <span class="brown--text font-weight-bold">{{ cta[locale] }}</span>
          </v-btn>
        </v-col> -->
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    videoLoaded: false,
    videoUrl:
      "https://www.youtube.com/embed/i7_rzQqrQ8s?autoplay=1&mute=1&loop=1&playlist=i7_rzQqrQ8s",
    locale: "en",
    title: {
      en: "Brighten Your Children's Future",
      ms: "Terangi Masa Depan Anak Anda",
      zh: "照亮您孩子的未来",
    },
    description: {
      en: "Discover fun and engaging learning with Hamochi and his friends.",
      ms: "Terokai pembelajaran yang menyeronokkan dan interaktif bersama Hamochi dan rakan-rakannya.",
      zh: "与 Hamochi 和他的朋友们一起学习。",
    },
    cta: {
      en: "Get Started for FREE!",
      ms: "Mulakan Secara PERCUMA!",
      zh: "立即开始！",
    },
  }),
  created() {
    setTimeout(() => {
      this.loadVideo();
    }, 800);
  },
  mounted() {
    this.locale = this.$_getLocale();
  },
  methods: {
    loadVideo() {
      this.videoLoaded = true;
    },
  },
};
</script>

<style scoped>
.shiny-golden-btn {
  background: linear-gradient(135deg,
      #ffcc33,
      #ffd700);
  /* Base golden gradient */
  border: 2px solid #e6b800;
  /* Border matches the golden theme */
}

.video-thumbnail {
  width: 100%;
  height: 360px;
  position: relative;
  cursor: pointer;
}
</style>